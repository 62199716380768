<!-- eslint-disable quotes -->
<!-- eslint-disable quotes -->
<!-- eslint-disable quotes -->
<!-- eslint-disable space-before-function-paren -->
<template>
  <div>
    <v-sheet min-height="70vh" rounded="lg">
      <!-- <model-gltf src="img/3d/s-plomb.gltf"></model-gltf> -->
      <!-- <model-obj src="img/3d/Motorcycle_engine.obj"></model-obj> -->
      <!-- <model-gltf src="3d/plomb.glb"></model-gltf> -->
      <model-viewer
        id="lazy-load"
        camera-controls
        reveal="interaction"
        style="height:90vh;width:100%"
        src="img/3d/pipe.gltf"
        alt="pipe"
        camera-orbit="340deg 80deg 50m"
        camera-target="-1m 3m -10m"
      >
        <div id="lazy-load-poster" slot="poster"></div>
        <div id="button-load" slot="poster">Загрузить 3D модель</div>
      </model-viewer>
    </v-sheet>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line space-before-function-paren
  mounted() {
    document
      // eslint-disable-next-line space-before-function-paren, quotes
      .querySelector("#button-load")
      // eslint-disable-next-line space-before-function-paren, quotes
      .addEventListener(
        // eslint-disable-next-line quotes
        "click",
        () =>
          // eslint-disable-next-line space-before-function-paren, quotes
          document.querySelector("#lazy-load").dismissPoster()
        // eslint-disable-next-line semi
      );
  }
  // eslint-disable-next-line semi
};
</script>
<style>
#lazy-load-poster {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url("/img/img/pipe.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
#button-load {
  background-image: url("/img/3d/download.svg");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: 6% 50%;
  background-color: grey;
  color: white;
  cursor: pointer;
  border-radius: 6px;
  display: inline-block;
  padding: 10px 18px 9px 40px;
  font-weight: 500;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 99;
}
</style>
